import React, { useState } from 'react';
import { Idl, Program } from '@coral-xyz/anchor';
import { SetAdmin } from './SetAdmin';
import { SetManager } from './SetManager';
import { WhitelistNewMarket } from './WhitelistNewMarket';
import { SetTreasurer } from './SetTreasurer';
import { RemoveWhitelistedMarket } from './RemoveWhitelistedMarket';
import { MarketAccountInterface } from '../types/ProxyAccountInterface';
import { SetTrader } from './SetTrader';

interface ManageProxyProps {
    proxyProgram: Program<Idl>,
    selectedPhoenixMarket: MarketAccountInterface | null,
    setSelectedPhoenixMarket: (x: MarketAccountInterface | null) => void,
    selectedWhirlpoolMarket: MarketAccountInterface | null,
    setSelectedWhirlpoolMarket: (x: MarketAccountInterface | null) => void,
    selectedRaydiumMarket: MarketAccountInterface | null,
    setSelectedRaydiumMarket: (x: MarketAccountInterface | null) => void,
    selectedMeteoraMarket: MarketAccountInterface | null,
    setSelectedMeteoraMarket: (x: MarketAccountInterface | null) => void,
    selectedLifinityMarket: MarketAccountInterface | null,
    setSelectedLifinityMarket: (x: MarketAccountInterface | null) => void
    selectedRaydiumCpmmMarket: MarketAccountInterface | null,
    setSelectedRaydiumCpmmMarket: (x: MarketAccountInterface | null) => void
    selectedRaydiumAMMMarket: MarketAccountInterface | null,
    setSelectedRaydiumAMMMarket: (x: MarketAccountInterface | null) => void
    selectedOpenbookV2Market: MarketAccountInterface | null,
    setSelectedOpenbookV2Market: (x: MarketAccountInterface | null) => void
}

export const ManageProxy: React.FC<ManageProxyProps> = (props: ManageProxyProps) => {
    const [selectedOption, setSelectedOption] = useState<string>('Whirlpool');


    return <>
        <RemoveWhitelistedMarket selectedPhoenixMarket={props.selectedPhoenixMarket}
                                 selectedWhirlpoolMarket={props.selectedWhirlpoolMarket}
                                 setSelectedPhoenixMarket={props.setSelectedPhoenixMarket}
                                 setSelectedWhirlpoolMarket={props.setSelectedWhirlpoolMarket}
                                 selectedRaydiumMarket={props.selectedRaydiumMarket}
                                 setSelectedRaydiumMarket={props.setSelectedRaydiumMarket}
                                 selectedMeteoraMarket={props.selectedMeteoraMarket}
                                 setSelectedMeteoraMarket={props.setSelectedMeteoraMarket}
                                 selectedLifinityMarket={props.selectedLifinityMarket}
                                 setSelectedLifinityMarket={props.setSelectedLifinityMarket}
                                 selectedRaydiumCpmmMarket={props.selectedRaydiumCpmmMarket}
                                 setSelectedRaydiumCpmmMarket={props.setSelectedRaydiumCpmmMarket}
                                 selectedRaydiumAMMMarket={props.selectedRaydiumAMMMarket}
                                 setSelectedRaydiumAMMMarket={props.setSelectedRaydiumAMMMarket}
                                 selectedOpenbookV2Market={props.selectedOpenbookV2Market}
                                 setSelectedOpenbookV2Market={props.setSelectedOpenbookV2Market}
                                 proxyProgram={props.proxyProgram}
                                 selectedOption={selectedOption}
                                 setSelectedOption={setSelectedOption}
                                 />
    </>;
}
