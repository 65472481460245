import {Button} from '@chakra-ui/react';
import React, {Dispatch, SetStateAction, useState} from 'react';
import {noncePublickey, proxyProgramID} from '../common';
import * as anchor from '@coral-xyz/anchor';
import {useAnchorWallet, useConnection} from '@solana/wallet-adapter-react';
import {MarketAccountInterface} from '../types/ProxyAccountInterface';
import {useNotify} from './Notify';
import {Transaction, TransactionInstruction} from "@solana/web3.js";
import {
    fetchNonceInfo,
    getAdvanceNonceIx,
    getComputeUnit,
    getPriorityFeeIx,
    getProxyPdaAccount,
    NonceInfo
} from "../onchain-utils";

export enum Dexes {
    PHOENIX,
    WHIRLPOOL,
    RAYDIUM,
    METEORA,
    LIFINITY,
}

interface RemoveWhitelistedMarketProps {
    selectedRaydiumMarket: MarketAccountInterface | null;
    setSelectedRaydiumMarket: (x: MarketAccountInterface | null) => void;
    selectedPhoenixMarket: MarketAccountInterface | null;
    setSelectedPhoenixMarket: (x: MarketAccountInterface | null) => void;
    selectedWhirlpoolMarket: MarketAccountInterface | null;
    setSelectedWhirlpoolMarket: (x: MarketAccountInterface | null) => void;
    selectedMeteoraMarket: MarketAccountInterface | null;
    setSelectedMeteoraMarket: (x: MarketAccountInterface | null) => void;
    selectedLifinityMarket: MarketAccountInterface | null;
    setSelectedLifinityMarket: (x: MarketAccountInterface | null) => void;
    selectedRaydiumCpmmMarket: MarketAccountInterface | null;
    setSelectedRaydiumCpmmMarket: (x: MarketAccountInterface | null) => void;
    selectedRaydiumAMMMarket: MarketAccountInterface | null,
    setSelectedRaydiumAMMMarket: (x: MarketAccountInterface | null) => void
    selectedOpenbookV2Market: MarketAccountInterface | null,
    setSelectedOpenbookV2Market: (x: MarketAccountInterface | null) => void
    proxyProgram: any;
    selectedOption: string;
    setSelectedOption: Dispatch<SetStateAction<string>>;
}

export const RemoveWhitelistedMarket: React.FC<RemoveWhitelistedMarketProps> = (props: RemoveWhitelistedMarketProps) => {
    const wallet = useAnchorWallet();
    const {connection} = useConnection();

    const [marketToRemove, setMarketToRemove] = useState<MarketAccountInterface>();
    const [marketToPause, setMarketToPause] = useState<MarketAccountInterface>();
    const notify = useNotify();

    async function removeWhitelistedMarket() {
        if (wallet && marketToRemove) {
            const [proxyPdaAccount] = getProxyPdaAccount();

            let [marketPda] = anchor.web3.PublicKey.findProgramAddressSync(
                [
                    marketToRemove.programAddress.toBuffer(),
                    marketToRemove.marketAddress.toBuffer(),
                ],
                proxyProgramID,
            );

            try {
                notify('info');
                const tx = await props.proxyProgram.methods
                    .removeMarket()
                    .accounts({
                        market: marketPda,
                        proxy: proxyPdaAccount,
                        manager: wallet.publicKey,
                    })
                    .rpc();
                notify('success', tx);
            } catch (e) {
                console.log(e);
                notify('error');

            }
        }
    }

    async function pauseUnPauseMarket() {
        if (wallet && marketToPause) {
            const [proxyPdaAccount] = getProxyPdaAccount();

            let [marketPda] = anchor.web3.PublicKey.findProgramAddressSync(
                [
                    marketToPause.programAddress.toBuffer(),
                    marketToPause.marketAddress.toBuffer(),
                ],
                proxyProgramID,
            );


            try {
                notify('info');
                let tx = new Transaction()
                let treasurerIx: TransactionInstruction;

                tx.feePayer = wallet?.publicKey;
                const {nonce, currentSlot}: NonceInfo = await fetchNonceInfo(connection, noncePublickey);
                tx.recentBlockhash = nonce;

                const advanceNonceIx = getAdvanceNonceIx(wallet.publicKey, noncePublickey)
                const priorityFeeIx = await getPriorityFeeIx(proxyPdaAccount, props.proxyProgram.provider)
                const computeUnitIx = getComputeUnit();

                if (marketToPause.isPaused) {
                    treasurerIx = await props.proxyProgram.methods
                        .unpauseMarket()
                        .accounts({
                            market: marketPda,
                            proxy: proxyPdaAccount,
                            treasurer: wallet.publicKey,
                        }).preInstructions([priorityFeeIx])
                        .instruction();
                } else {
                    treasurerIx = await props.proxyProgram.methods
                        .pauseMarket()
                        .accounts({
                            market: marketPda,
                            proxy: proxyPdaAccount,
                            treasurer: wallet.publicKey,
                        }).preInstructions([priorityFeeIx])
                        .instruction();
                }

                tx.add(advanceNonceIx);
                tx.add(computeUnitIx);
                tx.add(priorityFeeIx)
                tx.add(treasurerIx);
                const signedTx = await wallet.signTransaction(tx);

                const signature = await props.proxyProgram.provider.connection.sendRawTransaction(signedTx.serialize(),
                    {skipPreflight: true, preflightCommitment: 'processed', maxRetries: 30});


                await props.proxyProgram.provider.connection.confirmTransaction({
                    minContextSlot: currentSlot,
                    nonceAccountPubkey: noncePublickey,
                    nonceValue: tx.recentBlockhash,
                    signature: signature
                }, {minContextSlot: currentSlot, commitment: 'confirmed', skipPreflight: true})

                notify('success', signature);
            } catch (e) {
                console.log(e);
                notify('error');
            }
        }
    }

    const removeLifinityMarket = async () => {
        props.setSelectedOption('Lifinity v1');
        if (props.selectedPhoenixMarket) {
            setMarketToRemove(props.selectedPhoenixMarket);
            await removeWhitelistedMarket();
        }
    };

    const removeRaydiumCpmmMarket = async () => {
        props.setSelectedOption('Raydium CPMM');
        if (props.selectedPhoenixMarket) {
            setMarketToRemove(props.selectedPhoenixMarket);
            await removeWhitelistedMarket();
        }
    };

    const removeMeteoraMarket = async () => {
        props.setSelectedOption('Meteora DLMM');
        if (props.selectedPhoenixMarket) {
            setMarketToRemove(props.selectedPhoenixMarket); 
            await removeWhitelistedMarket();
        }
    };

    const removePhoenixMarket = async () => {
        props.setSelectedOption('Phoenix v1');
        if (props.selectedPhoenixMarket) {
            setMarketToRemove(props.selectedPhoenixMarket);
            await removeWhitelistedMarket();
        }
    };

    const removeWpMarket = async () => {
        props.setSelectedOption('Orca Whirlpool');
        if (props.selectedWhirlpoolMarket) {
            setMarketToRemove(props.selectedWhirlpoolMarket);
            await removeWhitelistedMarket();
        }
    };

    const removeRaydiumMarket = async () => {
        props.setSelectedOption('Raydium CLMM');
        if (props.selectedRaydiumMarket) {
            setMarketToRemove(props.selectedRaydiumMarket);
            await removeWhitelistedMarket();
        }
    };

    const removeOpenbookV2arket = async () => {
        props.setSelectedOption('Openbook v2');
        if (props.selectedOpenbookV2Market) {
            setMarketToRemove(props.selectedOpenbookV2Market);
            await removeWhitelistedMarket();
        }
    };

    const removeRaydiumAMMMarket = async () => {
        props.setSelectedOption('Raydium AMMv4');
        if (props.selectedRaydiumAMMMarket) {
            setMarketToRemove(props.selectedRaydiumAMMMarket);
            await removeWhitelistedMarket();
        }
    };

    const pauseMarket = async (market: string) => {
        if (market === 'Orca Whirlpool' && props.selectedWhirlpoolMarket) {
            setMarketToPause(props.selectedWhirlpoolMarket);
            props.setSelectedOption('Orca Whirlpool');
        }

        if (market === 'Phoenix v1' && props.selectedPhoenixMarket) {
            setMarketToPause(props.selectedPhoenixMarket);
            props.setSelectedOption('Phoenix v1');
        }

        if (market === 'Raydium CLMM' && props.selectedRaydiumMarket) {
            setMarketToPause(props.selectedRaydiumMarket);
            props.setSelectedOption('Raydium CLMM');
        }

        if (market === 'Lifinity v2' && props.selectedLifinityMarket) {
            setMarketToPause(props.selectedLifinityMarket);
            props.setSelectedOption('Lifinity v1');
        }

        if (market === 'Raydium CPMM' && props.selectedRaydiumCpmmMarket) {
            setMarketToPause(props.selectedRaydiumCpmmMarket);
            props.setSelectedOption('Raydium CPMM');
        }

        if (market === 'Meteora DLMM' && props.selectedMeteoraMarket) {
            setMarketToPause(props.selectedMeteoraMarket);
            props.setSelectedOption('Meteora DLMM');
        }

        if (market === 'Raydium AMMv4' && props.selectedRaydiumAMMMarket) {
            setMarketToPause(props.selectedRaydiumAMMMarket);
            props.setSelectedOption('Raydium AMMv4');
        }

        if (market === 'Openbook v2' && props.selectedOpenbookV2Market) {
            setMarketToPause(props.selectedOpenbookV2Market);
            props.setSelectedOption('Openbook v2');
        }

        await pauseUnPauseMarket();
    };

    return <>{props.selectedPhoenixMarket ? <div>
        <p >
            {`Phoenix v1 Market: ${props.selectedPhoenixMarket.marketAddress}`}
        </p>
        <Button onClick={() => pauseMarket('Phoenix v1')} >
            {props.selectedPhoenixMarket.isPaused ? `Unpause` : 'Pause'}
        </Button>
        <Button onClick={() => props.setSelectedPhoenixMarket(null)} >
            {`Cancel`}
        </Button>
    </div> : ''}
        {(props.selectedWhirlpoolMarket && true) ? <div>
            <p >
                {`Orca Whirlpool Market: ${props.selectedWhirlpoolMarket.marketAddress}`}
            </p>
            <Button onClick={() => pauseMarket('Orca Whirlpool')} >
                {props.selectedWhirlpoolMarket.isPaused ? `Unpause` : 'Pause'}
            </Button>
            <Button onClick={() => props.setSelectedWhirlpoolMarket(null)} >
                {`Cancel`}
            </Button>
        </div> : ''}
        {(props.selectedRaydiumMarket && true) ? <div>
            <p >
                {`Raydium CLMM Market: ${props.selectedRaydiumMarket.marketAddress}`}
            </p>
            <Button onClick={() => pauseMarket('Raydium CLMM')} >
                {props.selectedRaydiumMarket.isPaused ? `Unpause` : 'Pause'}
            </Button>
            <Button onClick={() => props.setSelectedRaydiumMarket(null)} >
                {`Cancel`}
            </Button>
        </div> : ''}
        {(props.selectedLifinityMarket && true) ? 
        <div>
            <p > 
                {`Lifinity v2 Market: ${props.selectedLifinityMarket.marketAddress}`}
            </p>
            <Button onClick={() => pauseMarket('Lifinity v2')} >
                {props.selectedLifinityMarket.isPaused ? `Unpause` : 'Pause'}
            </Button>
            <Button onClick={() => props.setSelectedLifinityMarket(null)} >
                {`Cancel`}
            </Button>
        </div> : ''}
        {(props.selectedRaydiumCpmmMarket && true) ? <div>
            <p >
                {`Raydium CPMM Market: ${props.selectedRaydiumCpmmMarket.marketAddress}`}
            </p>
            <Button onClick={() => pauseMarket('Raydium CPMM')} >
                {props.selectedRaydiumCpmmMarket.isPaused ? `Unpause` : 'Pause'}
            </Button>
            <Button onClick={() => props.setSelectedRaydiumCpmmMarket(null)} >
                {`Cancel`}
            </Button>
        </div> : ''}
        {(props.selectedMeteoraMarket && true) ? <div>
            <p >
                {`Meteora DLMM Market: ${props.selectedMeteoraMarket.marketAddress}`}
            </p>
            <Button onClick={() => pauseMarket('Meteora DLMM')} >
                {props.selectedMeteoraMarket.isPaused ? `Unpause` : 'Pause'}
            </Button>
            <Button onClick={() => props.setSelectedMeteoraMarket(null)} >
                {`Cancel`}
            </Button>
        </div> : ''}
        {(props.selectedOpenbookV2Market && true) ? <div>
            <p >
                {`Openbook v2 Market: ${props.selectedOpenbookV2Market.marketAddress}`}
            </p>
            <Button onClick={() => pauseMarket('Openbook v2')} >
                {props.selectedOpenbookV2Market.isPaused ? `Unpause` : 'Pause'}
            </Button>
            <Button onClick={() => props.setSelectedOpenbookV2Market(null)} >
                {`Cancel`}
            </Button>
        </div> : ''}
        {(props.selectedRaydiumAMMMarket && true) ? <div>
            <p >
                {`Raydium AMMv4 Market: ${props.selectedRaydiumAMMMarket.marketAddress}`}
            </p>
            <Button onClick={() => pauseMarket('Raydium AMMv4')} >
                {props.selectedRaydiumAMMMarket.isPaused ? `Unpause` : 'Pause'}
            </Button>
            <Button onClick={() => props.setSelectedRaydiumAMMMarket(null)} >
                {`Cancel`}
            </Button>
        </div> : ''}
    </>;
};
