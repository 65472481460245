import {PublicKey} from "@solana/web3.js";
import usdcLogo from './logo/USDC_LOGO.png';
import solLogo from './logo/SOL_LOGO.png';
import hntLogo from './logo/HNT_LOGO.png';
import jtoLogo from './logo/JTO_LOGO.png';
import bonkLogo from './logo/BONK_LOGO.png';
import rayLogo from './logo/RAY_LOGO.png';
import jupLogo from './logo/JUP_LOGO.png';
import pythLogo from './logo/PYTH_LOGO.png';
import renderLogo from './logo/RENDER_LOGO.png';
import auryLogo from './logo/AURY_LOGO.png';
import honeyLogo from './logo/HONEY_LOGO.png';
import orcaLogo from './logo/ORCA_LOGO.png';
import usdtLogo from './logo/USDT_LOGO.png';
import wifLogo from './logo/WIF_LOGO.png';
import wenLogo from './logo/WEN_LOGO.png';
import nosLogo from './logo/NOS_LOGO.png';
import shdwLogo from './logo/SDHW_LOGO.png';
import wLogo from './logo/W_LOGO.png';
import kmnoLogo from './logo/KMNO_LOGO.png';
import borgLogo from './logo/BORG_LOGO.png';
import motherLogo from './logo/MOTHER_LOGO.png';
import wxmLogo from './logo/WXM_LOGO.png';
import zexLogo from './logo/ZEX_LOGO.png';
import bsolLogo from './logo/BSOL_LOGO.png';
import jitosolLogo from './logo/JITOSOL_LOGO.png';
import msolLogo from './logo/MSOL_LOGO.png';
import cloudLogo from './logo/CLOUD_LOGO.png';
import xbgLogo from './logo/XBG_LOGO.png';
import alephLogo from './logo/ALEPH_LOGO.png';
import vchfLogo from './logo/VCHF_LOGO.png';
import veurLogo from './logo/VEUR_LOGO.png';
import driftLogo from './logo/DRIFT_LOGO.png';
import ponkeLogo from './logo/PONKE_LOGO.png';
import tnsrLogo from './logo/TNSR_LOGO.png';
import natixLogo from './logo/NATIX_LOGO.png';

export const proxyProgramID = new PublicKey(process.env.REACT_APP_OMS_PROXY_PROGRAM_ID as string);
export const noncePublickey = new PublicKey(process.env.REACT_APP_NONCE_PUBKEY as string);

export const phoenixProgramId = "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY";
export const whirlpoolProgramId = "whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc"
export const raydiumProgramId = "CAMMCzo5YL8w4VFF8KVHrK22GGUsp5VTaW7grrKgrWqK"
export const lifinityProgramId = "2wT8Yq49kHgDzXuPxZSaeLaH1qbmGXtEyPy64bL7aD3c"
export const raydiumCpmmProgramId = "CPMMoo8L3F4NbTegBCKVNunggL7H1ZpdTHKxQB5qKP1C"
export const meteoraProgramId = "LBUZKhRxPF3XUpBCjp4YzTKgLccjZhTSDM9YuVaPwxo"
export const openbookV2ProgramId = "opnb2LAfJYbRMAHHvqjCwQxanZn7ReEHp1k81EohpZb"
export const raydiumAmmV4ProgramId = "675kPX9MHTjS2zt1qfr1NYHuzeLXfQM9H24wFSUt1Mp8"

export const cryptoLogos = {
    USDC: usdcLogo,
    SOL: solLogo,
    HNT: hntLogo,
    JTO: jtoLogo,
    BONK: bonkLogo,
    RAY: rayLogo,
    JUP: jupLogo,
    PYTH: pythLogo,
    RENDER: renderLogo,
    AURY: auryLogo,
    HONEY: honeyLogo,
    ORCA: orcaLogo,
    USDT: usdtLogo,
    WIF: wifLogo,
    WEN: wenLogo,
    NOS: nosLogo,
    SHDW: shdwLogo,
    W: wLogo,
    KMNO: kmnoLogo,
    BORG: borgLogo,
    MOTHER: motherLogo,
    WXM: wxmLogo,
    ZEX: zexLogo,
    BSOL: bsolLogo,
    JITOSOL: jitosolLogo,
    MSOL: msolLogo,
    CLOUD: cloudLogo,
    XBG: xbgLogo,
    ALEPH: alephLogo,
    VCHF: vchfLogo,
    VEUR: veurLogo,
    DRIFT: driftLogo,
    PONKE: ponkeLogo,
    TNSR: tnsrLogo,
    NATIX: natixLogo,
};

export interface SupportedAsset {
    value: string;
    label: string;
    img: string;
    mint: PublicKey;
    balance?: string;
    decimals: number;
}

export interface SolanaFmInterface {
    blockTime: number;
    confirmationStatus: string;
    err: string;
    memo: string;
    signature: string;
    slot: number;
}

export interface TxHistoryInterface {
    signature: string;
    intruction: string | null;
}

export interface CoinAddressInterface {
    usdc: PublicKey,
    sol: PublicKey,
    hnt: PublicKey,
    jto: PublicKey,
    bonk: PublicKey,
    ray: PublicKey,
    jup: PublicKey,
    pyth: PublicKey,
    render: PublicKey,
    aury: PublicKey,
    honey: PublicKey,
    orca: PublicKey,
    usdt: PublicKey,
    wif: PublicKey,
    wen: PublicKey,
    nos: PublicKey,
    shdw: PublicKey,
    w: PublicKey,
    kmno: PublicKey,
    borg: PublicKey,
    mother: PublicKey,
    wxm: PublicKey,
    zex: PublicKey,
    bsol: PublicKey,
    jitosol: PublicKey,
    msol: PublicKey,
    cloud: PublicKey,
    xbg: PublicKey,
    aleph: PublicKey,
    vchf: PublicKey,
    veur: PublicKey,
    ponke: PublicKey,
    tnsr: PublicKey,
    natix: PublicKey,
    drift: PublicKey,
}
export const explorerCLusters:  Record<string,string> = {
    MAINNET: "",
    DEVNET: "cluster=devnet"
}
export const coinAddresses: Record<string, CoinAddressInterface> = {
    MAINNET: {
        usdc: new PublicKey('EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'),
        sol: new PublicKey('So11111111111111111111111111111111111111112'),
        hnt: new PublicKey('hntyVP6YFm1Hg25TN9WGLqM12b8TQmcknKrdu1oxWux'),
        jto: new PublicKey('jtojtomepa8beP8AuQc6eXt5FriJwfFMwQx2v2f9mCL'),
        bonk: new PublicKey('DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263'),
        ray: new PublicKey('4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R'),
        jup: new PublicKey('JUPyiwrYJFskUPiHa7hkeR8VUtAeFoSYbKedZNsDvCN'),
        pyth: new PublicKey('HZ1JovNiVvGrGNiiYvEozEVgZ58xaU3RKwX8eACQBCt3'),
        render: new PublicKey('rndrizKT3MK1iimdxRdWabcF7Zg7AR5T4nud4EkHBof'),
        aury: new PublicKey("AURYydfxJib1ZkTir1Jn1J9ECYUtjb6rKQVmtYaixWPP"),
        honey: new PublicKey("4vMsoUT2BWatFweudnQM1xedRLfJgJ7hswhcpz4xgBTy"),
        orca: new PublicKey("orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE"),
        usdt: new PublicKey("Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB"),
        wif: new PublicKey("EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm"),
        wen: new PublicKey("WENWENvqqNya429ubCdR81ZmD69brwQaaBYY6p3LCpk"),
        nos: new PublicKey("nosXBVoaCTtYdLvKY6Csb4AC8JCdQKKAaWYtx2ZMoo7"),
        shdw: new PublicKey("SHDWyBxihqiCj6YekG2GUr7wqKLeLAMK1gHZck9pL6y"),
        w: new PublicKey("85VBFQZC9TZkfaptBWjvUw7YbZjy52A6mjtPGjstQAmQ"),
        kmno: new PublicKey("KMNo3nJsBXfcpJTVhZcXLW7RmTwTt4GVFE7suUBo9sS"),
        borg: new PublicKey("3dQTr7ror2QPKQ3GbBCokJUmjErGg8kTJzdnYjNfvi3Z"),
        mother: new PublicKey("3S8qX1MsMqRbiwKg2cQyx7nis1oHMgaCuc9c4VfvVdPN"),
        wxm: new PublicKey("wxmJYe17a2oGJZJ1wDe6ZyRKUKmrLj2pJsavEdTVhPP"),
        zex: new PublicKey("ZEXy1pqteRu3n13kdyh4LwPQknkFk3GzmMYMuNadWPo"),
        bsol: new PublicKey("bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1"),
        jitosol: new PublicKey("J1toso1uCk3RLmjorhTtrVwY9HJ7X8V9yYac6Y7kGCPn"),
        msol: new PublicKey("mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So"),
        cloud: new PublicKey("CLoUDKc4Ane7HeQcPpE3YHnznRxhMimJ4MyaUqyHFzAu"),
        xbg: new PublicKey("XBGdqJ9P175hCC1LangCEyXWNeCPHaKWA17tymz2PrY"),
        aleph: new PublicKey("3UCMiSnkcnkPE1pgQ5ggPCBv6dXgVUy16TmMUe1WpG9x"),
        vchf: new PublicKey("AhhdRu5YZdjVkKR3wbnUDaymVQL2ucjMQ63sZ3LFHsch"),
        veur: new PublicKey("C4Kkr9NZU3VbyedcgutU6LKmi6MKz81sx6gRmk5pX519"),
        drift: new PublicKey("DriFtupJYLTosbwoN8koMbEYSx54aFAVLddWsbksjwg7"),
        tnsr: new PublicKey("TNSRxcUxoT9xBG3de7PiJyTDYu7kskLqcpddxnEJAS6"),
        ponke: new PublicKey("5z3EqYQo9HiCEs3R84RCDMu2n7anpDMxRhdK8PSWmrRC"),
        natix: new PublicKey("FRySi8LPkuByB7VPSCCggxpewFUeeJiwEGRKKuhwpKcX"),
    },
    DEVNET: {
        usdc: new PublicKey('DK1gsSV2EubSE5S5FdXHpGzw2cAJNVzxeXRmAfxAMpU5'),
        sol: new PublicKey('B1sL3zxwyVnDGzRWCAsBkjL23wyu8HgwQP4XxgnHiSrv'),
        hnt: new PublicKey('H8UekPGwePSmQ3ttuYGPU1szyFfjZR4N53rymSFwpLPm'),
        jto: new PublicKey('jtojtomepa8beP8AuQc6eXt5FriJwfFMwQx2v2f9mCL'),
        bonk: new PublicKey('DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263'),
        ray: new PublicKey('4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R'),
        jup: new PublicKey('JUPyiwrYJFskUPiHa7hkeR8VUtAeFoSYbKedZNsDvCN'),
        pyth: new PublicKey('HZ1JovNiVvGrGNiiYvEozEVgZ58xaU3RKwX8eACQBCt3'),
        render: new PublicKey('rndrizKT3MK1iimdxRdWabcF7Zg7AR5T4nud4EkHBof'),
        aury: new PublicKey("AURYydfxJib1ZkTir1Jn1J9ECYUtjb6rKQVmtYaixWPP"),
        honey: new PublicKey("4vMsoUT2BWatFweudnQM1xedRLfJgJ7hswhcpz4xgBTy"),
        orca: new PublicKey("orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE"),
        usdt: new PublicKey("Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB"),
        wif: new PublicKey("EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm"),
        wen: new PublicKey("WENWENvqqNya429ubCdR81ZmD69brwQaaBYY6p3LCpk"),
        nos: new PublicKey("nosXBVoaCTtYdLvKY6Csb4AC8JCdQKKAaWYtx2ZMoo7"),
        shdw: new PublicKey("SHDWyBxihqiCj6YekG2GUr7wqKLeLAMK1gHZck9pL6y"),
        w: new PublicKey("85VBFQZC9TZkfaptBWjvUw7YbZjy52A6mjtPGjstQAmQ"),
        kmno: new PublicKey("KMNo3nJsBXfcpJTVhZcXLW7RmTwTt4GVFE7suUBo9sS"),
        borg: new PublicKey("3dQTr7ror2QPKQ3GbBCokJUmjErGg8kTJzdnYjNfvi3Z"),
        mother: new PublicKey("3S8qX1MsMqRbiwKg2cQyx7nis1oHMgaCuc9c4VfvVdPN"),
        wxm: new PublicKey("wxmJYe17a2oGJZJ1wDe6ZyRKUKmrLj2pJsavEdTVhPP"),
        zex: new PublicKey("ZEXy1pqteRu3n13kdyh4LwPQknkFk3GzmMYMuNadWPo"),
        bsol: new PublicKey("bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1"),
        jitosol: new PublicKey("J1toso1uCk3RLmjorhTtrVwY9HJ7X8V9yYac6Y7kGCPn"),
        msol: new PublicKey("mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So"),
        cloud: new PublicKey("CLoUDKc4Ane7HeQcPpE3YHnznRxhMimJ4MyaUqyHFzAu"),
        xbg: new PublicKey("XBGdqJ9P175hCC1LangCEyXWNeCPHaKWA17tymz2PrY"),
        aleph: new PublicKey("3UCMiSnkcnkPE1pgQ5ggPCBv6dXgVUy16TmMUe1WpG9x"),
        vchf: new PublicKey("AhhdRu5YZdjVkKR3wbnUDaymVQL2ucjMQ63sZ3LFHsch"),
        veur: new PublicKey("C4Kkr9NZU3VbyedcgutU6LKmi6MKz81sx6gRmk5pX519"),
        drift: new PublicKey("DriFtupJYLTosbwoN8koMbEYSx54aFAVLddWsbksjwg7"),
        tnsr: new PublicKey("TNSRxcUxoT9xBG3de7PiJyTDYu7kskLqcpddxnEJAS6"),
        ponke: new PublicKey("5z3EqYQo9HiCEs3R84RCDMu2n7anpDMxRhdK8PSWmrRC"),
        natix: new PublicKey("FRySi8LPkuByB7VPSCCggxpewFUeeJiwEGRKKuhwpKcX"),

    }
};
