import { PublicKey } from '@solana/web3.js';
import { ProxyAccountInterface } from '../types/ProxyAccountInterface';
import React from 'react';
import { Link } from '@chakra-ui/react';
import { EXPLORER_CLUSTER } from './App';
import { proxyProgramID } from '../common';

interface ProxyAddressDetailsProps {
    proxyPdaAccount: PublicKey,
    proxyAccount: ProxyAccountInterface
}

const url = (address: string): string => {
    return `https://solscan.io/account/${address}?${EXPLORER_CLUSTER}`;
};

export const ProxyAddressDetails: React.FC<ProxyAddressDetailsProps> = (props: ProxyAddressDetailsProps) => {
    return <>
        Proxy:
        <br />
        - Program ID: <Link
        className={'proxy-details'} isExternal
        href={url(proxyProgramID.toString())}>{proxyProgramID.toString()}</Link>
        <br />
        - Wallet: <Link
        className={'proxy-details'} isExternal
        href={url(props.proxyPdaAccount.toString())}>{props.proxyPdaAccount.toString()}</Link>
        <br />
        <br />
    </>;
};
