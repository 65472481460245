import {Button, Input} from '@chakra-ui/react';
import React, {useCallback, useEffect, useState} from 'react';
import {useAnchorWallet, useConnection} from '@solana/wallet-adapter-react';
import {
    Transaction,
    TransactionInstruction
} from '@solana/web3.js';
import * as anchor from '@coral-xyz/anchor';
import {
    noncePublickey,
    SupportedAsset,
    proxyProgramID
} from '../common';
import {ProxyAccountInterface} from '../types/ProxyAccountInterface';
import {AnchorProvider, BN, Idl} from '@coral-xyz/anchor';
import omsProxyIdlStage from '../omsProxyIdlStage.json';
import omsProxyIdlProd from '../omsProxyIdlProd.json';
import {getAssociatedTokenAddress} from '@solana/spl-token';
import {CryptoOptions} from './CryptoOptions';
import {assetsSupported} from './App';
import {useNotify} from './Notify';
import {
    fetchNonceInfo,
    getAdvanceNonceIx,
    getComputeUnit,
    getPriorityFeeIx,
    getProxyPdaAccount,
    NonceInfo
} from "../onchain-utils";

function isItNativeToken(selectedOption: SupportedAsset): boolean {
    let native = false;
    if (selectedOption.label === 'SOL')
        native = true;
    return native;
}

export const ManageFunds: React.FC = () => {
    const {connection} = useConnection();
    const wallet = useAnchorWallet();

    const [proxyAccount, setProxyAccount] = useState<ProxyAccountInterface>();
    const [proxyProgram, setProxyProgram] = useState<any>();
    const [selectedOption, setSelectedOption] = useState<SupportedAsset>(assetsSupported[0]);
    const [amount, setAmount] = useState<number>(0);
    const [proxyPdaAccount] = getProxyPdaAccount();
    const notify = useNotify();

    const loadProxyProgram = useCallback(async () => {
        if (wallet) {
            const provider = new AnchorProvider(connection, wallet, {commitment: 'processed'});
            anchor.setProvider(provider);

            const proxyProgram = new anchor.Program((proxyProgramID.toString() === omsProxyIdlStage.address ? omsProxyIdlStage : omsProxyIdlProd) as Idl, provider);
            setProxyAccount(proxyAccount);
            setProxyProgram(proxyProgram);
        }
    }, [connection, wallet, proxyAccount])

    useEffect(() => {
        loadProxyProgram().catch(console.error);
    }, [loadProxyProgram]);

    const treasurerAction = async (isDeposit: boolean) => {
        if (wallet) {
            notify('info');

            const tokenProgram = (await connection.getAccountInfo(selectedOption.mint))?.owner
            const proxyATA = await getAssociatedTokenAddress(selectedOption.mint, proxyPdaAccount, true, tokenProgram);
            const treasurerATA = await getAssociatedTokenAddress(selectedOption.mint, wallet.publicKey, false, tokenProgram);
            let native = isItNativeToken(selectedOption);
            let treasurerIx: TransactionInstruction;
            let tx = new Transaction()
            tx.feePayer = wallet?.publicKey;
            const {nonce, currentSlot}: NonceInfo = await fetchNonceInfo(connection, noncePublickey);
            tx.recentBlockhash = nonce;

            const advanceNonceIx = getAdvanceNonceIx(wallet.publicKey, noncePublickey)
            const priorityFeeIx = await getPriorityFeeIx(proxyATA, proxyProgram.provider)
            const computeUnitIx = getComputeUnit();

            if (isDeposit) {
                treasurerIx = await proxyProgram.methods
                    .deposit(new BN(amount * Math.pow(10, selectedOption.decimals)), native)
                    .accounts({
                        proxy: proxyPdaAccount,
                        proxyAssociatedTokenAccount: proxyATA,
                        treasurer: wallet.publicKey,
                        treasurerAssociatedTokenAccount: native ? null : treasurerATA,
                        tokenMint: selectedOption.mint,
                        tokenProgram: tokenProgram
                    })
                    .instruction();


            } else {
                treasurerIx = await proxyProgram.methods
                    .withdraw(new BN(amount * Math.pow(10, selectedOption.decimals)), native)
                    .accounts({
                        proxy: proxyPdaAccount,
                        proxyAssociatedTokenAccount: proxyATA,
                        treasurer: wallet.publicKey,
                        treasurerAssociatedTokenAccount: treasurerATA,
                        tokenMint: selectedOption.mint,
                        tokenProgram: tokenProgram
                    })
                    .instruction();
            }


            tx.add(advanceNonceIx);
            tx.add(computeUnitIx);
            tx.add(priorityFeeIx);
            tx.add(treasurerIx);
            const signedTx = await wallet.signTransaction(tx);

            try {
                const signature = await proxyProgram.provider.connection.sendRawTransaction(signedTx.serialize(),
                    {skipPreflight: true, preflightCommitment: 'processed', maxRetries: 30});

                await proxyProgram.provider.connection.confirmTransaction({
                    minContextSlot: currentSlot,
                    nonceAccountPubkey: noncePublickey,
                    nonceValue: tx.recentBlockhash,
                    signature: signature
                }, {minContextSlot: currentSlot, commitment: 'confirmed', skipPreflight: true})

                notify('success', signature);
            } catch (error) {
                console.error(error);
                notify('error');
                throw error;
            }

        }
    }

    const manageDepositWithdraw = useCallback(async (isDeposit: boolean) => {
        await treasurerAction(isDeposit);
        // eslint-disable-next-line
    }, [wallet, connection, amount, selectedOption]);


    return <>
        <CryptoOptions setSelectedOption={setSelectedOption} selectedOption={selectedOption}/>
        <Input
            type='number'
            placeholder='Enter amount'
            value={amount}
            onChange={(e) => setAmount(parseFloat(e.target.value))}
        />
        <Button onClick={() => manageDepositWithdraw(true)} disabled={!wallet?.publicKey} value={amount}>
            Deposit
        </Button>
        <Button onClick={() => manageDepositWithdraw(false)}>
            Withdraw
        </Button>
    </>;
};
