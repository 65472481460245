import { Box, Button, Menu, MenuButton, MenuItem, MenuList, Image } from '@chakra-ui/react';
import { ChevronDownIcon } from "@chakra-ui/icons";
import React from 'react';
import { SupportedAsset } from '../common';
import { assetsSupported } from './App';


interface CryptoOptionProps {
    selectedOption: SupportedAsset;
    setSelectedOption: (x: SupportedAsset) => any;
}

export const CryptoOptions: React.FC<CryptoOptionProps> = (props: CryptoOptionProps) => {
    return (
        <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                {props.selectedOption ? props.selectedOption.label : "Select Option"}
            </MenuButton>
            <MenuList>
                {assetsSupported.sort((a, b) => a.value.localeCompare(b.value)).map((option) => (
                    <MenuItem key={option.value} onClick={() => props.setSelectedOption(option)} margin={0}>
                        <Box display="flex" alignItems="center">
                            <Image src={option.img} boxSize="24px" mr="2" />
                            {option.label}
                        </Box>
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    );
};
