import React, {useCallback, useEffect, useState} from 'react';
import {PublicKey} from '@solana/web3.js';
import * as anchor from '@coral-xyz/anchor';
import {
    lifinityProgramId,
    raydiumCpmmProgramId,
    meteoraProgramId, openbookV2ProgramId,
    phoenixProgramId,
    proxyProgramID, raydiumAmmV4ProgramId,
    raydiumProgramId,
    whirlpoolProgramId,
} from '../common';
import {MarketAccountInterface, ProxyAccountInterface} from '../types/ProxyAccountInterface';
import {useAnchorWallet, useConnection} from '@solana/wallet-adapter-react';
import {AnchorProvider, BorshAccountsCoder, Idl, Program} from '@coral-xyz/anchor';
import omsProxyIdlStage from '../omsProxyIdlStage.json';
import omsProxyIdlProd from '../omsProxyIdlProd.json';
import {Button, Menu, MenuButton, MenuItem, MenuList} from '@chakra-ui/react';
import {CheckIcon, ChevronDownIcon, CloseIcon} from '@chakra-ui/icons';
import {ManageProxy} from './ManageProxy';
import {ProxyAddressDetails} from './ProxyAddressDetails';
import {getProxyPdaAccount} from "../onchain-utils";

export const MainProxy: React.FC = () => {
    const [selectedPhoenixMarket, setSelectedPhoenixMarket] = useState<MarketAccountInterface | null>(null);
    const [selectedWhirlpoolMarket, setSelectedWhirlpoolMarket] = useState<MarketAccountInterface | null>(null);
    const [selectedRaydiumMarket, setSelectedRaydiumMarket] = useState<MarketAccountInterface | null>(null);
    const [selectedMeteoraMarket, setSelectedMeteoraMarket] = useState<MarketAccountInterface | null>(null);
    const [selectedLifinityMarket, setSelectedLifinityMarket] = useState<MarketAccountInterface | null>(null);
    const [selectedRaydiumCpmmMarket, setSelectedRaydiumCpmmMarket] = useState<MarketAccountInterface | null>(null);
    const [selectedOpenbookV2Market, setSelectedOpenbookV2Market] = useState<MarketAccountInterface | null>(null);
    const [selectedRaydiumAMMMarket, setSelectedRaydiumAMMMarket] = useState<MarketAccountInterface | null>(null);

    const [proxyAccount, setProxyAccount] = useState<ProxyAccountInterface>();
    const [phoenixMarketAccounts, setPhoenixMarketAccounts] = useState<MarketAccountInterface[]>();
    const [whirlpoolMarketAccounts, setWhirlpoolMarketAccounts] = useState<MarketAccountInterface[]>();
    const [raydiumMarketAccounts, setRaydiumMarketAccounts] = useState<MarketAccountInterface[]>();
    const [lifinityMarketAccounts, setLifinityMarketAccounts] = useState<MarketAccountInterface[]>();
    const [raydiumCpmmMarketAccounts, setRaydiumCpmmMarketAccounts] = useState<MarketAccountInterface[]>();
    const [meteoraMarketAccounts, setMeteoraMarketAccounts] = useState<MarketAccountInterface[]>();
    const [openbookV2MarketAccounts, setOpenbookV2MarketAccounts] = useState<MarketAccountInterface[]>();
    const [raydiumAMMMarketAccounts, setRaydiumAMMMarketAccounts] = useState<MarketAccountInterface[]>();

    const [proxyProgram, setProxyProgram] = useState<Program<Idl>>();
    const [proxyPdaAccount] = getProxyPdaAccount();

    const {connection} = useConnection();
    const wallet = useAnchorWallet();

    const loadProxyInformations = useCallback(async () => {
        if (wallet) {
            const provider = new AnchorProvider(connection, wallet, {commitment: 'processed'});
            anchor.setProvider(provider);
            
            const localProxyProgram = new anchor.Program((proxyProgramID.toString() === omsProxyIdlStage.address ? omsProxyIdlStage : omsProxyIdlProd) as Idl, provider);
            const allAccounts = await connection.getProgramAccounts(proxyProgramID, {commitment: 'confirmed'});
            const borsh = new BorshAccountsCoder(localProxyProgram.idl);
            let phoenixMarkets: MarketAccountInterface[] = [];
            let whirlpoolMarkets: MarketAccountInterface[] = [];
            let raydiumMarkets: MarketAccountInterface[] = [];
            let meteoraMarkets: MarketAccountInterface[] = [];
            let lifinityMarkets: MarketAccountInterface[] = [];
            let raydiumCpmmMarkets: MarketAccountInterface[] = [];
            let openbookV2Markets: MarketAccountInterface[] = [];
            let raydiumAMMMarkets: MarketAccountInterface[] = [];

            // eslint-disable-next-line array-callback-return
            allAccounts.map(account => {
                try {
                    const market = borsh.decode('market', account.account.data);
                    if (new PublicKey(market.programAddress).toString() === phoenixProgramId)
                        phoenixMarkets.push(market);

                    if (new PublicKey(market.programAddress).toString() === whirlpoolProgramId)
                        whirlpoolMarkets.push(market);

                    if (new PublicKey(market.programAddress).toString() === raydiumProgramId)
                        raydiumMarkets.push(market);

                    if (new PublicKey(market.programAddress).toString() === meteoraProgramId)
                        meteoraMarkets.push(market);

                    if (new PublicKey(market.programAddress).toString() === lifinityProgramId)
                        lifinityMarkets.push(market);

                    if (new PublicKey(market.programAddress).toString() === raydiumCpmmProgramId)
                        raydiumCpmmMarkets.push(market);

                    if (new PublicKey(market.programAddress).toString() === openbookV2ProgramId)
                        openbookV2Markets.push(market);

                    if (new PublicKey(market.programAddress).toString() === raydiumAmmV4ProgramId)
                        raydiumAMMMarkets.push(market);

                } catch (e) {
                    console.log(e);
                }
            });
            // @ts-ignore
            const proxyAccount: any = await localProxyProgram.account.proxy.fetch(proxyPdaAccount);
            setProxyAccount(proxyAccount as ProxyAccountInterface);
            setPhoenixMarketAccounts(phoenixMarkets);
            setWhirlpoolMarketAccounts(whirlpoolMarkets);
            setRaydiumMarketAccounts(raydiumMarkets);
            setLifinityMarketAccounts(lifinityMarkets);
            setRaydiumCpmmMarketAccounts(raydiumCpmmMarkets);
            setMeteoraMarketAccounts(meteoraMarkets);
            setRaydiumAMMMarketAccounts(raydiumAMMMarkets);
            setOpenbookV2MarketAccounts(openbookV2Markets);
            setProxyProgram(localProxyProgram);
        }
        // eslint-disable-next-line
    }, [connection, wallet]);

    useEffect(() => {
        loadProxyInformations().catch(console.error);
    }, [loadProxyInformations]);


    return proxyAccount ? <div className='title'>
        <ProxyAddressDetails proxyPdaAccount={proxyPdaAccount} proxyAccount={proxyAccount}/>
        <Menu>
            {({isOpen}) => (
                <>
                    <MenuButton isActive={isOpen} as={Button} rightIcon={<ChevronDownIcon/>}>
                        {isOpen ? 'Close' : 'Lifinity v2'}
                    </MenuButton>
                    {lifinityMarketAccounts ? <MenuList >
                        {lifinityMarketAccounts.map((marketInfos, index) => (
                            <MenuItem key={index}
                                      onClick={() => setSelectedLifinityMarket(marketInfos)} margin={0}>
                                {marketInfos.marketAddress.toString()} {marketInfos.isPaused ?
                                <CloseIcon marginLeft={'10px'}/> : <CheckIcon marginLeft={'10px'}/>}
                            </MenuItem>
                        ))}
                    </MenuList> : ''}
                </>
            )}
        </Menu>
        <Menu>
            {({isOpen}) => (
                <>
                    <MenuButton isActive={isOpen} as={Button} rightIcon={<ChevronDownIcon/>}>
                        {isOpen ? 'Close' : 'Meteora DLMM'}
                    </MenuButton>
                    {meteoraMarketAccounts ? <MenuList >
                        {meteoraMarketAccounts.map((marketInfos, index) => (
                            <MenuItem  key={index}
                                      onClick={() => setSelectedMeteoraMarket(marketInfos)} margin={0}>
                                {marketInfos.marketAddress.toString()} {marketInfos.isPaused ?
                                <CloseIcon marginLeft={'10px'}/> : <CheckIcon marginLeft={'10px'}/>}
                            </MenuItem>
                        ))}
                    </MenuList> : ''}
                </>
            )}
        </Menu>
        <Menu>
            {({isOpen}) => (
                <>
                    <MenuButton isActive={isOpen} as={Button} rightIcon={<ChevronDownIcon/>}>
                        {isOpen ? 'Close' : 'Openbook v2'}
                    </MenuButton>
                    {openbookV2MarketAccounts ? <MenuList >
                        {openbookV2MarketAccounts.map((marketInfos, index) => (
                            <MenuItem key={index}
                                      onClick={() => setSelectedOpenbookV2Market(marketInfos)} margin={0}>
                                {marketInfos.marketAddress.toString()} {marketInfos.isPaused ?
                                <CloseIcon marginLeft={'10px'}/> : <CheckIcon marginLeft={'10px'}/>}
                            </MenuItem>
                        ))}
                    </MenuList> : ''}
                </>
            )}
        </Menu>
        <Menu>
            {({isOpen}) => (
                <>
                    <MenuButton isActive={isOpen} as={Button} rightIcon={<ChevronDownIcon/>}>
                        {isOpen ? 'Close' : 'Orca Whirlpool'}
                    </MenuButton>
                    {whirlpoolMarketAccounts ? <MenuList >
                        {whirlpoolMarketAccounts.map((marketInfos, index) => (
                            <MenuItem  key={index}
                                      onClick={() => setSelectedWhirlpoolMarket(marketInfos)} margin={0}>
                                {marketInfos.marketAddress.toString()} {marketInfos.isPaused ?
                                <CloseIcon marginLeft={'10px'}/> : <CheckIcon marginLeft={'10px'}/>}

                            </MenuItem>
                        ))}
                    </MenuList> : ''}
                </>
            )}
        </Menu>
        <Menu>
            {({isOpen}) => (
                <>
                    <MenuButton isActive={isOpen} as={Button} rightIcon={<ChevronDownIcon/>}>
                        {isOpen ? 'Close' : 'Phoenix v1'}
                    </MenuButton>
                    {phoenixMarketAccounts ? <MenuList >
                        {phoenixMarketAccounts.map((marketInfos, index) => (
                            <MenuItem key={index}
                                      onClick={() => setSelectedPhoenixMarket(marketInfos)} margin={0}>
                                {marketInfos.marketAddress.toString()} {marketInfos.isPaused ?
                                <CloseIcon marginLeft={'10px'}/> : <CheckIcon marginLeft={'10px'}/>}
                            </MenuItem>
                        ))}
                    </MenuList> : ''}
                </>
            )}
        </Menu>
        <Menu>
            {({isOpen}) => (
                <>
                    <MenuButton isActive={isOpen} as={Button} rightIcon={<ChevronDownIcon/>}>
                        {isOpen ? 'Close' : 'Raydium AMMv4'}
                    </MenuButton>
                    {raydiumAMMMarketAccounts ? <MenuList >
                        {raydiumAMMMarketAccounts.map((marketInfos, index) => (
                            <MenuItem  key={index}
                                      onClick={() => setSelectedRaydiumAMMMarket(marketInfos)} margin={0}>
                                {marketInfos.marketAddress.toString()} {marketInfos.isPaused ?
                                <CloseIcon marginLeft={'10px'}/> : <CheckIcon marginLeft={'10px'}/>}
                            </MenuItem>
                        ))}
                    </MenuList> : ''}
                </>
            )}
        </Menu>
        <Menu>
            {({isOpen}) => (
                <>
                    <MenuButton isActive={isOpen} as={Button} rightIcon={<ChevronDownIcon/>}>
                        {isOpen ? 'Close' : 'Raydium CLMM'}
                    </MenuButton>
                    {raydiumMarketAccounts ? <MenuList >
                        {raydiumMarketAccounts.map((marketInfos, index) => (
                            <MenuItem  key={index}
                                      onClick={() => setSelectedRaydiumMarket(marketInfos)} margin={0}>
                                {marketInfos.marketAddress.toString()} {marketInfos.isPaused ?
                                <CloseIcon marginLeft={'10px'}/> : <CheckIcon marginLeft={'10px'}/>}
                            </MenuItem>
                        ))}
                    </MenuList> : ''}
                </>
            )}
        </Menu>
        <Menu>
            {({isOpen}) => (
                <>
                    <MenuButton isActive={isOpen} as={Button} rightIcon={<ChevronDownIcon/>}>
                        {isOpen ? 'Close' : 'Raydium CPMM'}
                    </MenuButton>
                    <Button onClick={loadProxyInformations}>
                        Reload-Accounts
                    </Button>
                    {raydiumCpmmMarketAccounts ? <MenuList>
                        {raydiumCpmmMarketAccounts.map((marketInfos, index) => (
                            <MenuItem key={index}
                                      onClick={() => setSelectedRaydiumCpmmMarket(marketInfos)} margin={0}>
                                {marketInfos.marketAddress.toString()} {marketInfos.isPaused ?
                                <CloseIcon marginLeft={'10px'}/> : <CheckIcon marginLeft={'10px'}/>}
                            </MenuItem>
                        ))}
                    </MenuList> : ''}
                </>
            )}
        </Menu>

        {proxyProgram && <ManageProxy selectedPhoenixMarket={selectedPhoenixMarket}
                                      setSelectedPhoenixMarket={setSelectedPhoenixMarket}
                                      selectedRaydiumMarket={selectedRaydiumMarket}
                                      setSelectedRaydiumMarket={setSelectedRaydiumMarket}
                                      selectedWhirlpoolMarket={selectedWhirlpoolMarket}
                                      setSelectedWhirlpoolMarket={setSelectedWhirlpoolMarket}
                                      selectedMeteoraMarket={selectedMeteoraMarket}
                                      setSelectedMeteoraMarket={setSelectedMeteoraMarket}
                                      selectedLifinityMarket={selectedLifinityMarket}
                                      setSelectedLifinityMarket={setSelectedLifinityMarket}
                                      selectedRaydiumCpmmMarket={selectedRaydiumCpmmMarket}
                                      setSelectedRaydiumCpmmMarket={setSelectedRaydiumCpmmMarket}
                                      selectedRaydiumAMMMarket={selectedRaydiumAMMMarket}
                                      setSelectedRaydiumAMMMarket={setSelectedRaydiumAMMMarket}
                                      selectedOpenbookV2Market={selectedOpenbookV2Market}
                                      setSelectedOpenbookV2Market={setSelectedOpenbookV2Market}
                                      proxyProgram={proxyProgram}
        />}

    </div> : <></>;
}
