import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';
import { EXPLORER_CLUSTER } from './App';

export const useNotify = () => {
    const toast = useToast();

    return useCallback((status: string, tx?:string) => {
        if(status === 'success')
        return toast({
            title: 'Transaction sent and confirmed',
            description: `https://solscan.io/tx/${tx}?${EXPLORER_CLUSTER}`,
            status: 'success',
            duration: 7000,
            isClosable: true,
        });

        if(status === 'error')
            return   toast({
                title: 'Transaction was not sent',
                description: `Something went wrong`,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

        if(status === 'info')
            return   toast({
                title: 'Preparing transaction',
                description: `You should receive a wallet notification soon`,
                status: 'info',
                duration: 5000,
                isClosable: true,
            });
    }, [toast])
}
