import { List, ListItem, ListIcon, Link, Button } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import React, { useCallback, useState } from 'react';
import { SolanaFmInterface, TxHistoryInterface } from '../common';
import axios from 'axios';
import { useAnchorWallet } from '@solana/wallet-adapter-react';
import { EXPLORER_CLUSTER } from './App';

export const TransactionHistory: React.FC = () => {
    const [txHistory, setTxHistory] = useState<TxHistoryInterface[]>([]);
    const wallet = useAnchorWallet();

    const fetchTxHistory = useCallback(async () => {
        if (wallet)
            try {
                const nineDays = 1000000;
                const currentDate = Math.floor(Date.now() / 1000) - nineDays;
                let requestUrl = `https://api.solana.fm/v0/accounts/${wallet.publicKey}/transactions?utcFrom=${currentDate}&limit=5`;
                const response = await axios.get(requestUrl);
                let data: SolanaFmInterface[] = response.data.result.data;
                if (data.length > 0) {
                    let firstFive = data.slice(0, 5);
                    setTxHistory(firstFive.map(tx => ({
                        signature: tx.signature,
                        intruction: null,
                    })));

                    /*firstFive.map(async (e) => {
                        try {
                            //Transaction API down
                            requestUrl = `https://api.solana.fm/v0/transactions/${e.signature}/transfers`;
                            const response = await axios.get(requestUrl);
                        } catch (error) {
                            console.log(error);
                        }
                    });*/
                }
            } catch (error) {
                console.error(error);
            }
    }, [wallet]);


    return (
        <List spacing={3}>
            <div><span>Latest Transactions over 9 days</span> <Button onClick={fetchTxHistory}>
                Refresh
            </Button></div>
            {txHistory ? txHistory.map((tx, key) => {
                    const url = `https://solscan.io/tx/${tx.signature}?${EXPLORER_CLUSTER}`;
                    return <ListItem key={key}>
                        <ListIcon as={ExternalLinkIcon} color='green.500' />
                        <Link href={url} isExternal>{tx.signature}</Link>
                    </ListItem>;
                },
            ) : <></>}
        </List>
    );
};
