import {
    ComputeBudgetProgram, Connection, NonceAccount,
    PublicKey,
    RecentPrioritizationFees,
    SystemProgram,
    TransactionInstruction
} from '@solana/web3.js';

import * as anchor from '@coral-xyz/anchor';
import Provider from "@coral-xyz/anchor/dist/cjs/provider";
import {proxyProgramID} from "./common";

const MAXIMUM_PRIORITY_FEE = 10000000;
const MAXIMUM_COMPUTE_UNITS = 200000;

export interface NonceInfo {
    nonce: string,
    currentSlot: number
}

export function isValidSolanaAddress(inputValue: any) {
    return typeof inputValue === 'string' && (inputValue === '' || (inputValue.length >= 42 && inputValue.length <= 45));
}

export const getProxyPdaAccount = () => {
    return anchor.web3.PublicKey.findProgramAddressSync(
        [Buffer.from('proxy')],
        proxyProgramID,
    );
}

const estimatePrioritizationFee = async (ataAccounts: PublicKey[], provider: Provider): Promise<number> => {
    if(!MAXIMUM_PRIORITY_FEE) {
        const priorityFees = await provider.connection.getRecentPrioritizationFees({
            lockedWritableAccounts: ataAccounts,
        });

        //return the maximum priority fee over the last 150 slots
        return priorityFees.reduce(
            (max: number, item: RecentPrioritizationFees) => item.prioritizationFee > max ? item.prioritizationFee : max,
            priorityFees[0].prioritizationFee);
    }
    return Promise.resolve(MAXIMUM_PRIORITY_FEE);
}

export const getPriorityFeeIx = async (account: PublicKey, provider: Provider): Promise<TransactionInstruction> => {
    return ComputeBudgetProgram.setComputeUnitPrice({
        microLamports: await estimatePrioritizationFee(
            [account],
            provider
        ),
    })
}

export const getComputeUnit = (): TransactionInstruction => {
    return ComputeBudgetProgram.setComputeUnitLimit({
        units: MAXIMUM_COMPUTE_UNITS
    })
}

export const getAdvanceNonceIx = (authorizedPubkey: PublicKey, noncePubkey: PublicKey): TransactionInstruction => {
    return SystemProgram.nonceAdvance({
        authorizedPubkey,
        noncePubkey
    })
}

export const fetchNonceInfo = async (connection: Connection, noncePubkey: PublicKey): Promise<NonceInfo> => {
    const accountInfo = await connection.getAccountInfo(noncePubkey);
    const currentSlot = await connection.getSlot('confirmed');
    if (!accountInfo) throw new Error("No account info found");
    const nonceAccount = NonceAccount.fromAccountData(accountInfo.data);
    return {nonce: nonceAccount.nonce, currentSlot};
}
